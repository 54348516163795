<template>
  <v-container fluid>

    <v-data-table
      class="elevation-0 background-transparent"
      :headers="dataFetched ? headers : []"
      :items="dataFetched ? fetchedItems : []"
      :no-data-text="!totalItems ? '' : $lang.errors.noDataAvailable"
      :items-per-page="25"
      :server-items-length="totalItems"
      :options.sync="options"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:item="{ item, index }">
        <tr :key="index">
          <td v-for="header in headers" :key="header.value" class="position-relative">
            <v-row v-if="isPrimaryKey(header.value)" justify="center" align="center" style="min-width: 108px;">
              <v-btn
                v-if="selectedEntityDataPermissions.hasUpdate"
                class="button-help mr-1"
                icon
                small
                @click="openEditModal(item)"
              >
                <v-icon small color="info">mdi-pencil</v-icon>
              </v-btn>
              <action-button-with-confirmation
                :action-text="$lang.actions.areYouSureYouWantToDelete"
                :title="$lang.actions.delete"
                :is-disabled="!selectedEntityDataPermissions.hasDelete"
                :button-text="''"
                :button-color="'error'"
                :small-round="true"
                :data-cy="'browser-data-row-delete'"
                @closeAfterDelete="fetchDataFunction"
                @submit="deleteRow(item)"
                @closeDialog="''"
              />
              <a v-if="item[header.value] && item[header.value] !== 'null'" href="#" class="text-decoration-none ml-2" @click.prevent="handleLinkClick(item[header.value], header.value)">
                {{ item[header.value] }}
              </a>
              <div v-else>-</div>
            </v-row>
            <div v-else-if="hasRelation(header.value)">
              <a v-if="item[header.value] && item[header.value] !== 'null'" href="#" class="text-decoration-none ml-1" @click.prevent="handleLinkClick(item[header.value], header.value)">
                {{ item[header.value] }}
              </a>
              <div v-else>-</div>
            </div>
            <div v-else-if="isJSON(item[header.value])">
              <a href="#" class="text-decoration-none" @click.prevent="openFullJSONDialog(item[header.value], header.value)">
                {{ truncateString(item[header.value]) }}
              </a>
            </div>
            <div v-else-if="isTimestampDateType(item[header.value])">
              {{ item[header.value] !== null ? formatTimestamp(item[header.value]) : '-' }}
            </div>
            <div v-else>
              {{ item[header.value] !== 'null' ? item[header.value] : '-' }}
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <!-- <v-row no-gutters class="align-center"> -->
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%;">

          <div class="d-inline-flex align-center">
            <v-autocomplete
              ref="selectedEntity"
              v-model="selectedEntity"
              outlined
              dense
              clearable
              :items="sortedEntities"
              :loading="isLoadingEntities"
              :search-input.sync="searchEntities"
              hide-no-data
              hide-details
              item-text="name"
              class="ml-2 custom-search-perm"
              :label="$lang.labels.entity"
              :placeholder="$lang.hints.selectEntityToFetchData"
              prepend-inner-icon="mdi-cloud-search-outline"
              style="min-width: 350px"
              data-cy="search-by-entity"
            ></v-autocomplete>
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              data-cy="refresh"
              @click="sendQuery(false)"
            >
              <v-icon
                dense
                small
              >
                mdi-refresh
              </v-icon>
            </v-btn>
            <div class="d-flex align-center ml-2">
              <v-btn
                v-if="selectedEntity && selectedEntityDataPermissions.hasCreate"
                light
                color="accent"
                class="color-accent-text mr-1"
                data-cy="data-create"
                :loading="isLoading"
                @click="openCreateModal"
              >
                {{ $lang.actions.create }}
              </v-btn>
            </div>
          </div>
        </div>
        <v-progress-linear
          v-if="isLoadingOnEntityChange"
          indeterminate
          color="primary"
          class="mb-4"
        ></v-progress-linear>
        <div v-if="!isLoadingOnEntityChange && fields.length > 0" class="filter-section mt-5">
          <v-row align="center">
            <v-col
              v-for="field in fields"
              :key="field.name"
              cols="12"
              sm="6"
              md="4"
              lg="2"
              xl="3"
              class="mb-4 align-center"
            >
              <template v-if="['INTEGER', 'BIGINT', 'DOUBLE', 'FLOAT'].includes(field.dataType)">
                <div :class="{'d-flex align-center': field.relation && field.relation.id}">

                  <v-text-field
                    v-model.number="field.value"
                    :label="field.name"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="mx-2 align-center"
                    :hint="`Search type: ${field.searchType}, Matching pattern: ${field.matchingPattern}`"
                    type="number"
                  ></v-text-field>
                  <v-btn
                    v-if="field.relation && field.relation.id"
                    text
                    small
                    color="primary"
                    class="mx-1"
                    :data-cy="`browse-data-open-${field.name}`"
                    :disabled="!field.relation"
                    @click="openRelationBrowserData(field.relation.id)"
                  >
                    {{ $lang.actions.openTemplate }}
                  </v-btn>
                </div>

              </template>
              <template v-else-if="field.dataType === 'TIMESTAMP'">

                <template v-if="field.matchingPattern === 'BETWEEN'">
                  <div

                    class="d-flex mr-2 ml-2"
                    style="gap: 16px;"
                    :class="{'d-flex align-center': field.relation && field.relation.id}"
                  >
                    <v-datetime-picker
                      v-model="field.value.dateFrom"
                      :label="`${field.name} Start`"
                      outlined
                      dense
                      hide-details
                      clearable
                      class="mr-2"
                      :text-field-props="{ outlined: true, dense: true, 'hide-details': true }"
                    >
                      <template v-slot:dateIcon="{}">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template v-slot:timeIcon="{}">
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                    <v-datetime-picker
                      v-model="field.value.dateTill"
                      :label="`${field.name} End`"
                      outlined
                      dense
                      hide-details
                      clearable
                      class="mr-2"
                      :text-field-props="{ outlined: true, dense: true, 'hide-details': true }"
                    >
                      <template v-slot:dateIcon="{}">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template v-slot:timeIcon="{}">
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                    <v-btn
                      v-if="field.relation && field.relation.id"
                      text
                      small
                      color="primary"
                      class="mx-1"
                      :data-cy="`browse-data-open-${field.name}`"
                      :disabled="!field.relation"
                      @click="openRelationBrowserData(field.relation.id)"
                    >
                      {{ $lang.actions.openTemplate }}
                    </v-btn>
                  </div>
                </template>
                <template v-else>
                  <div
                    :class="{'d-flex align-center': field.relation && field.relation.id}"

                    class="d-flex mr-2 ml-2"
                    style="gap: 16px;"
                  >
                    <v-datetime-picker
                      v-model="field.value"
                      :label="field.name"
                      outlined
                      dense
                      hide-details
                      clearable
                      class="mr-2"
                      :text-field-props="{ outlined: true, dense: true, 'hide-details': true }"
                    >
                      <template v-slot:dateIcon="{}">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template v-slot:timeIcon="{}">
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                    <v-btn
                      v-if="field.relation && field.relation.id"
                      text
                      small
                      color="primary"
                      class="mx-1"
                      :data-cy="`browse-data-open-${field.name}`"
                      :disabled="!field.relation"
                      @click="openRelationBrowserData(field.relation.id)"
                    >
                      {{ $lang.actions.openTemplate }}
                    </v-btn>
                  </div>
                </template>
              </template>

              <template v-else-if="field.dataType === 'JSON'">
                <div :class="{'d-flex align-center': field.relation && field.relation.id}">

                  <v-text-field
                    v-model="field.value"
                    :label="field.name"
                    outlined
                    dense
                    readonly
                    clearable
                    hide-details
                    class="mx-2 ellipsis-field align-center"
                    :hint="`Search type: ${field.searchType}, Matching pattern: ${field.matchingPattern}`"
                    append-icon="mdi-pencil"
                    @click="field.showModal = true"
                    @click:append="field.showModal = true"
                  ></v-text-field>
                  <v-btn
                    v-if="field.relation && field.relation.id"
                    text
                    small
                    color="primary"
                    class="mx-1"
                    :data-cy="`browse-data-open-${field.name}`"
                    :disabled="!field.relation"
                    @click="openRelationBrowserData(field.relation.id)"
                  >
                    {{ $lang.actions.openTemplate }}
                  </v-btn>
                </div>

                <v-dialog v-if="field.showModal" v-model="field.showModal" max-width="75%">
                  <v-card class="pa-2" flat>
                    <p class="pb-0 mb-0">{{ field.name }}</p>
                    <codemirror v-model="field.value" :options="cmOptions" />
                    <div class="d-flex w-100 justify-end mt-1">
                      <v-btn
                        color="primary"
                        text
                        :disabled="!field.value"
                        @click="field.showModal = false"
                      >
                        {{ $lang.actions.save }}
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </template>
              <template v-else-if="field.dataType === 'BOOLEAN'">
                <div :class="{'d-flex align-center': field.relation && field.relation.id}">

                  <v-checkbox
                    v-model="field.value"
                    :label="field.name"
                    class="mx-2 align-center"
                    hide-details
                    dense
                    :hint="`Search type: ${field.searchType}, Matching pattern: ${field.matchingPattern}`"
                    data-cy="checkbox"
                  ></v-checkbox>
                </div>

              </template>
              <template v-else>
                <div :class="{'d-flex align-center': field.relation && field.relation.id}">
                  <v-text-field
                    v-model="field.value"
                    :label="field.name"
                    outlined
                    dense
                    hide-details
                    clearable
                    class="mx-2 align-center"
                    :hint="`Search type: ${field.searchType}, Matching pattern: ${field.matchingPattern}`"
                    data-cy="textfield"
                  ></v-text-field>
                  <v-btn
                    v-if="field.relation && field.relation.id"
                    text
                    small
                    color="primary"
                    class="mx-1"
                    :data-cy="`browse-data-open-${field.name}`"
                    :disabled="!field.relation"
                    @click="openRelationBrowserData(field.relation.id)"
                  >
                    {{ $lang.actions.openTemplate }}
                  </v-btn>
                </div>
              </template>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="2"
              xl="2"
              class="align-center mb-4"
            >
              <v-btn
                color="primary"
                class="mx-2"
                data-cy="fetch-button"
                @click="fetchDataFunction()"
              >
                Fetch
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </template>

    </v-data-table>

    <v-dialog
      v-if="createDataModal"
      v-model="createDataModal"
      max-width="60%"
      height="80vh"
      scrollable
    >
      <CreateEditModal
        ref="createEditModal"
        :is-edit="isEdit"
        :all-fields="allFields"
        :form-data="formData"
        :selected-entity="selectedEntity"
        :primary-key-value="primaryKeyValue"
        @close="closeCreateEditModal"
        @save="saveEntry"
        @reset-form-data="resetFormData"
        @show-snackbar="addSnackbar"
        @refresh-data="sendQuery(false)"
      />
    </v-dialog>
    <v-dialog v-model="showRelationDialog" max-width="75%" height="80vh">
      <v-card class="pa-2" flat style="height: 100%;">
        <p class="pb-0 mb-2">{{ relationDialogLabel }}</p>
        <v-card-text style="height: calc(100% - 56px); overflow-y: auto;">
          <json-custom-viewer :data="relationData" @closeDialog="showRelationDialog = false" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showRelationDialog = false">
            {{ $lang.actions.close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showJSONDialog" max-width="75%" height="80vh">
      <v-card class="pa-2" flat>
        <p class="pb-0 mb-2">{{ fullJsonLabel }}</p>
        <v-lazy style="width: 100%; height: 63vh; overflow-y: auto" class="py-2">

          <json-custom-viewer :data="fullJSON" @closeDialog="showJSONDialog = false" />
        </v-lazy>

        <div class="d-flex w-100 justify-end mt-1">
          <v-btn color="primary" text @click="showJSONDialog = false">
            {{ $lang.actions.close }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getEntitiesWithSystemPermissionRolesUsingGET as getEntities, getEntityByIdUsingGET as getEntity } from '@/utils/api'

import jsonCustomViewer from '@/components/ui/JsonCustomViewer'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'

import { mapState } from 'vuex'
import { fetchData, fetchDataById } from '@/services/dataBrowsing/dataBrowsing'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/3024-day.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'

// import js
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.js'

import '../../assets/scss/code-mirror.scss'
import { mapActions } from 'vuex'
import { format } from 'date-fns'
import CreateEditModal from './CreateEditModal.vue'
import { debounce } from 'lodash'
import { deleteEntityEntry } from '@/services/dataBrowsing/dataBrowsing'

export default {
  components: {
    codemirror,
    jsonCustomViewer,
    CreateEditModal,
    ActionButtonWithConfirmation
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoadingOnEntityChange: false,
      selectedEventData: null,
      isLoadingEntities: false,
      totalItems: 25,
      entities: [],
      searchEntities: '',
      dataFetched: false,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: []
      },
      lock: true,
      selectedEntity: '',
      headers: [],
      fields: [],
      allFields: [],
      fetchedItems: [],
      showJSONEditor: false,
      cmOptions: {
        gutters: [],
        tabSize: 4,
        mode: 'javascript',
        theme: this.$vuetify.theme.dark ? 'ayu-mirage' : '3024-day',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        autocorrect: true,
        autocomplete: true,
        highlightLines: true,
        viewportMargin: Infinity,
        matchBrackets: true,
        autoCloseBrackets: true
      },
      filterValues: {},
      selectedField: null,
      isLoading: false,
      showJSONDialog: false,
      fullJSON: '',
      fullJsonLabel: '',
      parsedFullJSON: '',
      showRelationDialog: false,
      relationDialogLabel: '',
      relationDialogData: '',
      relationData: null,
      selectedEntityWatchLock: false,
      hasUserInteracted: false,
      jsonValidationErrors: [],
      createDataModal: false,
      formData: {},
      isEdit: false,
      primaryKeyValue: null,
      jsonEditorModal: false,
      currentJsonFieldName: '',
      currentJsonFieldValue: '',
      selectedEntityDataPermissions: {
        id: 0,
        name: '',
        createdOn: '',
        modifiedOn: '',
        status: '',
        hasRead: false,
        hasCreate: false,
        hasUpdate: false,
        hasDelete: false
      }
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings']),
    sortedEntities() {
      return this.entities.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    fields: {
      handler(fields) {
        if (!this.hasUserInteracted) return

        const errorFields = []

        fields.forEach((field) => {
          if (field.dataType === 'JSON') {
            if (!this.isValidJSON(field.value)) {
              errorFields.push(field.name)
            }
          }
        })
        if (errorFields.length > 0) {

          this.addSnackbar({
            message: `${this.$lang.errors.invalidJson} ${errorFields.join(', ')}`,
            timeout: 5000,
            color: 'error'
          })
        }
      },
      deep: true,
      immediate: true
    },
    searchEntities: {
      handler: debounce( function (val) {
        if (val && val.length > 1 ) this.searchEntitiesFunction(val)
      }, 500)
    },
    options: {
      handler() {
        if (!this.lock) {
          this.sendQuery(true)
          this.savePreFill()
        }
      },
      deep: true
    },
    selectedEntity: {
      handler(val) {
        if (!this.lock && !this.selectedEntityWatchLock) {
          if (val) {
            this.$router.replace({ query: { entity: val } }).catch((err) => {
              if (err.name !== 'NavigationDuplicated') {
                throw err
              }
            })
            this.selectedEntityWatchLock = true
          } else {
            this.$router.replace({ query: {} })
          }

          const entity = this.entities.find((e) => e.name === val)

          if (entity) {
            this.selectedEntityDataPermissions = entity
          }

          const selectedEntityId = this.getEntityIdByName(val)

          if (selectedEntityId) {
            getEntity({ id: selectedEntityId })
              .then((res) => {
                const preFillData = JSON.parse(localStorage.getItem('preFillData')) || {}
                const browserDataSavedFilter = preFillData.browserDataSavedFilter || {}

                if (browserDataSavedFilter.dataBrowse !== selectedEntityId) {
                  preFillData.browserDataSavedFilter = {}
                  localStorage.setItem('preFillData', JSON.stringify(preFillData))
                }

                this.fetchEntityAndPrefillFilters(selectedEntityId)
                this.dataFetched = true
                this.fields = this.processFields(res.data.data.fields)
                this.allFields = res.data.data.fields
                this.setHeadersFromFields(res.data.data.fields)
                this.fetchDataFunction()
              })
              .catch((err) => {
                console.error('Error fetching entity data:', err)
                this.addSnackbar({
                  message: err,
                  timeout: 5000,
                  color: 'error'
                })
                this.fields = []
              })
          } else {
            this.fields = []
          }
        } else {
          this.selectedEntityWatchLock = false
        }
      }
    }
  },
  created() {
    const { entity } = this.$route.query

    this.lock = false
    this.$watch('fields', this.handleUserInteraction, { deep: true })

    if (entity && !this.lock) {
      getEntities({ name: entity })
        .then((res) => {
          this.entities = res.data.data.items
          this.isLoadingEntities = false
          this.selectedEntity = entity
        })
        .catch((err) => {
          this.isLoadingEntities = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    }

  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    isTimestampDateType(dataValue) {
      return dataValue && dataValue.toString().match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}$/)
    },
    getFieldLabel(field) {
      return field.required ? `${field.name} *` : field.name
    },
    validateJSON(field) {
      try {
        JSON.parse(field.value)
      } catch (e) {
        if (!this.jsonValidationErrors.includes(field.name)) {
          this.jsonValidationErrors.push(field.name)
        }
      }
    },
    isValidJSON(value) {
      if (!value) return true // Treat empty value as valid
      try {
        JSON.parse(value)

        return true
      } catch (e) {
        return false
      }
    },
    handleUserInteraction() {
      this.hasUserInteracted = true
    },

    async handleLinkClick(fieldValue, fieldName) {
      try {
        const field = this.allFields.find((f) => f.name === fieldName)

        if (field && field.relation) {
          const relationId = field.relation.id
          const res = await getEntity({ id: relationId })
          const result = res.data.data.name
          const data = await fetchDataById(result, fieldValue)

          if (data && data.data && data.data.body) {
            const { body } = data.data

            this.openModal(`${res.data.data.name}: ${fieldValue}`, body)
          }
        }
        if (field && field.primaryKey) {
          const data = await fetchDataById(this.selectedEntity, fieldValue)

          if (data && data.data && data.data.body) {
            const { body } = data.data

            this.openModal(`${this.selectedEntity}: ${fieldValue}`, body)
          }
        }
      } catch (error) {
        console.error('Error fetching related data:', error)
      }
    },
    formatTimestamp(item) {
      return format(new Date(item), 'yyyy-MM-dd HH:mm')
    },
    isPrimaryKey(fieldName) {
      const field = this.allFields.find((f) => f.name === fieldName)

      return field && field.primaryKey
    },
    hasRelation(fieldName) {
      const field = this.fields.find((f) => f.name === fieldName)

      return field && field.relation && field.searchType !== 'NO'
    },
    openModal(fieldName, data) {
      this.relationDialogLabel = fieldName
      this.relationData = data
      this.showRelationDialog = true
    },
    openRelationBrowserData(relationId) {
      getEntity({ id: relationId })
        .then((res) => {
          window.open(`/${localStorage.selectedLanguage || 'en'}/browse-data?entity=${res.data.data.name}`, '_blank')
        })
        .catch((err) => {
          console.error('Error fetching entity data:', err)
          this.fields = []
        })
    },
    fetchEntityAndPrefillFilters(selectedEntityId) {
      getEntity({ id: selectedEntityId })
        .then((res) => {
          this.dataFetched = true
          this.fields = this.processFields(res.data.data.fields)
          this.setHeadersFromFields(res.data.data.fields)

          const preFillData = JSON.parse(localStorage.getItem('preFillData')) || {}
          const browserDataSavedFilter = preFillData.browserDataSavedFilter || {}

          if (browserDataSavedFilter.dataBrowse === selectedEntityId) {
            this.filterValues = browserDataSavedFilter.filterValues || {}

            this.fields.forEach((field) => {
              if (field.dataType === 'TIMESTAMP' && field.matchingPattern === 'BETWEEN') {
                const dateFromKey = `${field.name}From`
                const dateTillKey = `${field.name}Till`

                field.value = {
                  dateFrom: this.filterValues[dateFromKey] ? new Date(this.filterValues[dateFromKey]) : '',
                  dateTill: this.filterValues[dateTillKey] ? new Date(this.filterValues[dateTillKey]) : ''
                }
              } else if (field.dataType === 'TIMESTAMP') {
                field.value = this.filterValues[field.name] ? new Date(this.filterValues[field.name]) : ''
              } else {
                field.value = this.filterValues[field.name] !== undefined ? this.filterValues[field.name] : ''
              }
            })
          }
        })
        .catch((err) => {
          console.error('Error fetching entity data:', err)
          this.fields = []
        })
    },
    truncateString(str, length = 32) {
      if (str && JSON.stringify(str).length <= length) {
        return JSON.stringify(str)
      }

      return str ? JSON.stringify(str).substring(0, length) + '...' : ''
    },
    isJSON(value) {
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return true
      }

      if (typeof value !== 'string') {
        return false
      }

      try {
        const parsed = JSON.parse(value)

        return typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed)
      } catch (e) {
        return false
      }
    },
    openFullJSONDialog(jsonStr, jsonLabel) {
      if (this.isJSON(jsonStr)) {
        try {
          this.fullJSON = JSON.parse(jsonStr)
        } catch (e) {
          this.fullJSON = jsonStr
        }
      } else {
        this.fullJSON = jsonStr
      }
      this.fullJsonLabel = jsonLabel
      this.showJSONDialog = true
    },
    formatJSON(value) {
      return this.truncateString(value)
    },

    async fetchDataFunction() {
      this.selectedEntityWatchLock = false
      this.isLoading = true
      this.fetchedItems = []

      try {
        const filterValuesList = {}

        this.selectedField = null

        this.fields.forEach((field) => {
          if (field.value !== '' && field.value !== null) {
            let { value } = field
            const { dataType } = field

            if (field.matchingPattern === 'BETWEEN' && dataType === 'TIMESTAMP') {
              if (field.value.dateFrom && field.value.dateTill) {
                filterValuesList[field.name] = {
                  dateFrom: new Date(field.value.dateFrom).toISOString().replace('Z', '+00:00'),
                  dateTill: new Date(field.value.dateTill).toISOString().replace('Z', '+00:00')
                }
              } else if (field.value.dateFrom) {
                filterValuesList[`${field.name}From`] = new Date(field.value.dateFrom).toISOString().replace('Z', '+00:00')
              } else if (field.value.dateTill) {
                filterValuesList[`${field.name}Till`] = new Date(field.value.dateTill).toISOString().replace('Z', '+00:00')
              }
            } else if (dataType === 'TIMESTAMP') {
              const parsedDate = new Date(value)

              if (!isNaN(parsedDate)) {
                value = parsedDate.toISOString().replace('Z', '+00:00')
              }
              filterValuesList[field.name] = value
            } else {
              filterValuesList[field.name] = value
            }
          }
        })
        this.filterValues = filterValuesList
        const dataWithFilters = await fetchData(this.selectedEntity, filterValuesList, this.options)

        switch (dataWithFilters.status) {

        case 400:
          this.fetchedItems = []
          this.addSnackbar({
            message: this.$lang.errors.dataEmpty,
            timeout: 5000,
            color: 'error'
          })
          this.isLoading = false

          return
        case 403:
          this.fetchedItems = []
          this.addSnackbar({
            message: this.$lang.errors.unauthorized,
            timeout: 5000,
            color: 'error'
          })
          this.isLoading = false

          return

        case 501:
          this.fetchedItems = []
          this.addSnackbar({
            message: this.$lang.errors.noShadowObject,
            timeout: 5000,
            color: 'error'
          })
          this.isLoading = false

          return

        case 500:
          this.fetchedItems = []
          if (dataWithFilters.data === null) {
            this.addSnackbar({
              message: this.$lang.errors.dataEmpty,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.addSnackbar({
              message: this.$lang.hints.technicalDifficulty,
              timeout: 5000,
              color: 'error'
            })
          }
          this.isLoading = false

          return
        default:
          break
        }

        if (dataWithFilters.data.body === null || dataWithFilters.data.body === undefined || dataWithFilters.data?.body?.meta?.totalItems === 0) {
          this.fetchedItems = []
          this.addSnackbar({
            message: this.$lang.errors.dataEmpty,
            timeout: 5000,
            color: 'error'
          })
          this.isLoading = false

          return
        }
        this.dataFetched = true
        const fetchedItems = dataWithFilters.data?.body?.items ? dataWithFilters.data.body.items : dataWithFilters.data.body && dataWithFilters.data.body.length > 0 ? dataWithFilters.data.body : []
        const dateTypeDataKeys = this.fields.filter((field) => field.dataType === 'TIMESTAMP').map((field) => field.name)

        this.fetchedItems = fetchedItems.map((item) => {
          const formattedItem = { ...item }

          Object.keys(formattedItem).forEach((key) => {
            if (dateTypeDataKeys.includes(key) && formattedItem[key] !== null && formattedItem[key] !== undefined) {
              formattedItem[key] = format(new Date(formattedItem[key]), 'yyyy-MM-dd HH:mm')
            }

            if (typeof formattedItem[key] === 'object') {
              formattedItem[key] = JSON.stringify(formattedItem[key], null, 2)
            }
          })

          return formattedItem
        })

        this.totalItems = dataWithFilters.data?.body?.meta?.totalItems || dataWithFilters.data?.body?.items?.length || dataWithFilters.data?.body?.length
        this.isLoading = false

      } catch (error) {
        this.fetchedItems = []
        this.addSnackbar({
          message: error,
          timeout: 5000,
          color: 'error'
        })
        this.isLoading = false
      } finally {
        this.savePreFill()

      }
    },

    processFields(fields) {
      return fields.filter((field) => field.searchType !== 'NO').map((field) => {
        let initialValue = ''

        if (field.dataType === 'TIMESTAMP' && field.matchingPattern === 'BETWEEN') {
          initialValue = { dateFrom: '', dateTill: '' }
        }

        return { ...field, value: initialValue, showModal: false }
      })
    },
    savePreFill() {
      const preFillData = JSON.parse(localStorage.getItem('preFillData')) || {}

      const filterValues = {}

      this.fields.forEach((field) => {
        if (field.value !== '' && field.value !== null) {
          if (field.matchingPattern === 'BETWEEN' && field.dataType === 'TIMESTAMP') {
            if (field.value.dateFrom && field.value.dateTill) {
              filterValues[field.name] = {
                dateFrom: field.value.dateFrom,
                dateTill: field.value.dateTill
              }
            } else if (field.value.dateFrom) {
              filterValues[`${field.name}From`] = field.value.dateFrom
            } else if (field.value.dateTill) {
              filterValues[`${field.name}Till`] = field.value.dateTill
            }
          } else {
            filterValues[field.name] = field.value
          }
        }
      })

      preFillData.browserDataSavedFilter = {
        dataBrowse: this.getEntityIdByName(this.selectedEntity),
        filterValues: filterValues
      }

      localStorage.setItem('preFillData', JSON.stringify(preFillData))
    },

    sendQuery(refreshOnly = false) {
      if (!refreshOnly) {
        this.fetchedItems = []
        this.headers = []
        this.fields = []
        this.dataFetched = false
        this.options.page = 1
      }

      const selectedEntityId = this.getEntityIdByName(this.selectedEntity)

      if (selectedEntityId) {
        getEntity({ id: selectedEntityId })
          .then((res) => {
            const preFillData = JSON.parse(localStorage.getItem('preFillData')) || {}
            const browserDataSavedFilter = preFillData.browserDataSavedFilter || {}

            if (browserDataSavedFilter.dataBrowse !== selectedEntityId) {
              preFillData.browserDataSavedFilter = {}
              localStorage.setItem('preFillData', JSON.stringify(preFillData))
            }

            this.fetchEntityAndPrefillFilters(selectedEntityId)
            this.formData = {}
            this.dataFetched = true
            this.fields = this.processFields(res.data.data.fields)
            this.allFields = res.data.data.fields
            this.setHeadersFromFields(res.data.data.fields)
            this.fetchDataFunction()
          })
          .catch((err) => {
            console.error('Error fetching entity data:', err)
            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })
            this.fields = []
          })
      } else {
        this.fields = []
      }
    },
    searchEntitiesFunction(val = '') {
      this.isLoadingEntities = true
      const obj = {}

      obj.page = 1
      obj.size = 500

      if (val && val.length > 1 && !this.lock ) obj.name = val
      getEntities(obj)
        .then((res) => {
          this.entities = res.data.data.items.filter((x) => x.hasRead)
          this.isLoadingEntities = false
        })
        .catch((err) => {
          this.isLoadingEntities = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })

    },
    getEntityIdByName(name) {
      const entity = this.entities.find((x) => x.name === name)

      return entity ? entity.id : null
    },
    setHeadersFromFields(fields) {
      const sortedFields = fields.sort((a, b) => {
        if (a.primaryKey && !b.primaryKey) {
          return -1
        }
        if (!a.primaryKey && b.primaryKey) {
          return 1
        }

        return 0
      })

      this.headers = sortedFields.map((field) => ({
        text: field.name,
        value: field.name,
        sortable: field.sortable
      }))
    },
    initializeFilterValues(fields) {
      this.filterValues = {}
      fields.forEach((field) => {
        if (field.searchType !== 'NO') {
          this.filterValues[field.name] = ''
        }
      })
    },
    openJSONEditor() {
      this.showJSONEditor = true
    },
    saveJSONDefaultValue() {
      this.showJSONEditor = false
    },
    openCreateModal() {
      this.resetFormData()
      this.isEdit = false
      this.createDataModal = true
    },
    openEditModal(item) {
      this.resetFormData()
      this.primaryKeyValue = null

      for (const key in item) {
        const field = this.allFields.find((f) => f.name === key)

        if (field) {
          if (field.primaryKey) {
            this.primaryKeyValue = item[key]
            this.formData[key] = item[key]
          } else {
            this.formData[key] = item[key]
          }
        }
      }
      this.isEdit = true
      this.createDataModal = true
    },
    closeCreateEditModal() {
      this.createDataModal = false
      this.resetFormData()
    },

    saveEntry() {
      this.$refs.createEditModal.saveEntry()
    },
    async deleteRow(item) {
      try {
        const res = await deleteEntityEntry(this.selectedEntity, item.id)

        if (res.status && res.status !== 200) {
          switch (res.status) {
          case 403:
            this.$emit('show-snackbar',{
              message: this.$lang.errors.unauthorized,
              timeout: 3000,
              color: 'error'
            })

            return
          case 501:
            this.$emit('show-snackbar',{
              message: this.$lang.errors.noShadowObject,
              timeout: 3000,
              color: 'error'
            })

            return
          default:
            break
          }

          this.$emit('show-snackbar', {
            message: res.statusText || this.$lang.errors.somethingWentWrong,
            timeout: 3000,
            color: 'error'
          })

          return
        }

        this.$emit('show-snackbar', {
          message: this.$lang.errors.fieldDeletedSuccessfully,
          timeout: 3000,
          color: 'success'
        })

        this.sendQuery(false)
      } catch (err) {
        this.$emit('show-snackbar', {
          message: err,
          timeout: 3000,
          color: 'error'
        })
        console.error(err)
      }
    },
    resetFormData() {
      this.formData = {}
      this.primaryKeyValue = null
      this.allFields.forEach((field) => {
        this.formData[field.name] = field.dataType === 'JSON' ? '' : null
      })
    }
  }
}
</script>

<style>
.ellipsis-field input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vue-codemirror {
  height: 40vh;
  width: 100%;
}
.CodeMirror-line  > span {
  padding-left: 20px;
}

</style>
